<template>
	<div v-if="dialogVisible">
		<el-dialog title="座位图" :visible.sync="dialogVisible" width="90%" :before-close="resetform">
			<div class="btnbox">
				<el-button type="primary" size="mini" class="font14" @click="submit">保存修改</el-button>
				<el-button type="primary" size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>
			</div>
			<div class="addbox" style="width: 100%;overflow: auto;">
				<div class="left_info" style="width: 1350px;margin:auto;">
					<div
						style="width: 100%;text-align: center;font-size: 0.22rem;font-weight: bold;margin-bottom: 15px;">
						{{room_name}}</div>
					
					
					<div style="display:flex;align-items: center;padding-left:40px;">
						<div v-for="(item,index) in zxset" style="display:flex;align-items: center;flex-direction: column;">
							<div class="inset" :id="'zx'+index" style="margin:5px;border-right: 1px solid #333;"
								:draggable="item.name?true:false" @dragstart="onDragStartsetzx(index, $event)"
								@dragover.prevent @drop="onDrop($event)">
								{{item.name}}
								
								<i v-show="item.name" class="el-icon-delete deleteicon" @click="deletezx(item,index)"></i>
							</div>
							<div>
								{{setnumber(index,zxset.length)}}
							</div>
							
						</div>
						
					</div>					
					<div class="zxt_im">主席台</div>
					<div
						style="margin-top: 20px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: relative;">
						<div v-for="(item,index) in list"
							style="display: flex;align-items: center;margin-bottom: 10px;">
							<div class="innerbox" v-if="index==0" v-for="(item2,index2) in item" style="display:flex;align-items: center;flex-direction: column;">
								<div class="inset" v-if="index==0" :id="index+','+index2" 
									:draggable="item2.name?true:false" @dragstart="onDragStartset(index+','+index2, $event)"
									@dragover.prevent @drop="onDrop($event)" :style="{
									marginRight:index2==3?'40px':index2==11?'40px':'0',
									borderRight:index2==3?'1px solid #333':index2==11?'1px solid #333':'',
									marginLeft:index2==0?'50px':''
								}">
									{{item2.name}}
									<i v-show="item2.name" class="el-icon-delete deleteicon" @click="deletezw(item2,index+','+index2)"></i>
								</div>
								
								<div :style="{marginRight:index2==3?'40px':index2==11?'40px':'0',marginLeft:index2==0?'50px':''}">
									{{(index+1)+'-'+(index2+1)}}
								</div>								
							</div>
							
							
							<div class="innerbox" v-if="index!=0" v-for="(item2,index2) in item" style="display:flex;align-items: center;flex-direction: column;">								
								<div class="inset" :id="index+','+index2"
									:draggable="item2.name?true:false" @dragstart="onDragStartset(index+','+index2, $event)"
									@dragover.prevent @drop="onDrop($event)" :style="{
									marginRight:index2==5?'40px':index2==13?'40px':'0',
									borderRight:index2==5?'1px solid #333':index2==13?'1px solid #333':''
								}">
									{{item2.name}}
									<i v-show="item2.name" class="el-icon-delete deleteicon" @click="deletezw(item2,index+','+index2)"></i>
								</div>
								<div :style="{marginRight:index2==5?'40px':index2==13?'40px':'0'}">
									{{(index+1)+'-'+(index2+1)}}
								</div>	
							</div>
							
						</div>
						<div style="position: absolute;left: -8%;">
							<div class="inset" v-for="item in 7"
								style="display: flex;align-items: center;margin-bottom: 30px;border: none;">
								第{{item}}排
							</div>
						</div>
						<div style="position: absolute;right: -14%;top:0;">
							<div
								style="display: flex;flex-direction: column;align-items: center;justify-content: center;font-weight: bold;color:#d00;margin-bottom: 10px;">
								待定人员列表</div>
							<div
								style="text-align: center;max-height: 55vh;overflow-y: auto;">
								<div v-for="(item,index) in userlist"
									style="border: 1px solid #ccc;border-radius: 10px;padding: 5px 10px;margin-bottom: 10px;cursor: pointer;"
									:draggable="true" @dragstart="onDragStart(index, $event)">
									{{item.name}}
								</div>
								<div v-if="userlist.length<=0"
									style="padding: 5px 10px;margin-bottom: 10px;cursor: pointer;color:#666;">
									已无数据~
								</div>
							</div>

						</div>
					</div>


				</div>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				room_name:'',
				dialogVisible: false,
				metting_id:'',
				list: [],
				draggingIndex: 0,
				userlist: [],
				yuanindex:'',//座位互拖-原始index
				
				zxset:[//主席台位置
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''}
				]
				
			}
		},
		created() {
			
		},
		methods: {
			submit(){
				console.log(this.list)
				console.log(this.zxset)
				this.$post({
					url: '/api/wyroomzc/hyzcbc',
					params: {
						wymeeting_id:this.metting_id,
						list:this.list,
						zxset:this.zxset
					}
				}).then((res) => {
					this.$message.success('保存成功')
				})
			},
			init(){//无数据时，初始化
				 this.list = []
				// 第一组 18 条数据
				var group1 = [];
				for (var i = 1; i <= 17; i++) {
					group1.push({user_id:'',name:''});
				}
				this.list.push(group1);
				
				// 后六组每组 20 条数据
				for (var j = 2; j <= 7; j++) {
					var group = [];
					for (var k = 1; k <= 20; k++) {
						group.push({user_id:'',name:''});
					}
					this.list.push(group);
				}
				this.zxset = [
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''},
					{user_id:'',name:''}
				]
				console.log(this.list)
			},
			deletezx(item,i){//主席台删除
				let itemstr = JSON.parse(JSON.stringify(item))
				this.$set(this.userlist, this.userlist.length, itemstr);
				this.zxset[i].name = ''
				this.zxset[i].user_id = ''
			},
			deletezw(item,index){//座位删除
				let itemstr = JSON.parse(JSON.stringify(item))
				this.$set(this.userlist, this.userlist.length, itemstr);
				let setindex = index.split(',')//目标座位
				this.list[setindex[0]][setindex[1]].name = ''
				this.list[setindex[0]][setindex[1]].user_id = ''
			},
			
			
			
			onDragStart(index, event) {
				this.yuanindex = ''
				this.draggingIndex = index;
				event.dataTransfer.effectAllowed = 'move';
				event.dataTransfer.setData('text/plain', index);
			},
			onDrop(event) {
				 event.stopPropagation()
				 event.preventDefault()

				let listindex = event.target.id.split(',')//目标座位
				console.log(listindex)
				if(listindex.length==2){//目标座位是普通座位
					if(this.yuanindex.length==1){//来源座位是主席台
						
						let mid = this.zxset[this.yuanindex[0]]
						this.$set(this.zxset,this.yuanindex[0],this.list[listindex[0]][listindex[1]])
						this.$set(this.list[listindex[0]],listindex[1],mid)
						return
					}
					if(this.yuanindex){//座位表互拖
						let mid = this.list[this.yuanindex[0]][this.yuanindex[1]]
						this.$set(this.list[this.yuanindex[0]],this.yuanindex[1],this.list[listindex[0]][listindex[1]])
						this.$set(this.list[listindex[0]],listindex[1],mid)
						return
						
					}					
					//用户列表拖拽入座
					this.list[listindex[0]][listindex[1]] = this.userlist[this.draggingIndex]				
					this.userlist.splice(this.draggingIndex, 1)
					
				}else{//目标座位是主席台
					listindex[0] = Number(listindex[0].substr(2))
					if(this.yuanindex.length==1){//来源座位是主席台
						let mid = this.zxset[this.yuanindex[0]]
						this.$set(this.zxset,this.yuanindex[0],this.zxset[listindex[0]])
						this.$set(this.zxset,listindex[0],mid)
						return
					}
					if(this.yuanindex){//来源座位是座位表
						let mid = this.list[this.yuanindex[0]][this.yuanindex[1]]
						this.$set(this.list[this.yuanindex[0]],this.yuanindex[1],this.zxset[listindex[0]])
						this.$set(this.zxset,listindex[0],mid)
						return
						
					}
					//用户列表拖拽进入主席台
					this.zxset[listindex[0]] = this.userlist[this.draggingIndex]				
					this.userlist.splice(this.draggingIndex, 1)
					
				}
							
			},
			onDragStartset(index,event){//座位表拖动
				this.yuanindex = index.split(',')
			},
			onDragStartsetzx(index,event){//主席台拖动
				this.yuanindex =[index]
			},
			setnumber(index,length){//显示座位下的序号				
				let jis=[]
				let ous=[]
				for(let i =1;i<=length;i++){
					if(i%2==0){
						ous.push(i)
					}else{
						jis.push(i)
					}
				}
				jis.sort((a, b) => b - a)
				let list = jis.concat(ous)
				return list[index]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.inset {
		width: 50px;
		height: 80px;
		border: 1px solid #333;
		padding: 5px;
		border-right: none;
		writing-mode: vertical-lr;
		display: flex;
		align-items: center;
		justify-content: center;
		text-orientation: upright;
		cursor: pointer;
		position: relative;
		line-height: 20px;
		.deleteicon{
			position: absolute;
			right:0;
			top:0;
			color:#d00;
			display: none;
		}
	}
	.inset:hover{
		.deleteicon{
			display: block;
		}
	}
	.innerbox:nth-last-child(1) {
		.inset{
			border-right: 1px solid #333;
		}
		
	}

	.zuowei_pai {
		display: flex;
		margin-bottom: 10px;
		width: 100%;
	}

	.zuowei_lie {
		padding: 5px 10px;
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.zuowei_lie:nth-last-child(1) {
		margin-right: 0;
	}

	.zxt {
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		padding-left: 40px;
	}

	.zxt_im {
		background-color: rgb(180, 180, 180);
		width: 80%;
		padding: 3px 0;
		text-align: center;
		font-size: 16px;
		margin-left: 40px;
	}

	.zxt_item {
		padding: 5px 10px;
		margin-right: 15px;
	}

	.zxt_item:nth-last-child(1) {
		margin-right: 0;
	}

	.left_info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		height: 80vh;
		width: 100%;
		padding: 30px;
		background-color: #fff;
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}
</style>